<template>
  <ManageList dialog-name="Lists" />
</template>
<script>
import ManageList from '@/views/app/manage/List'

export default {
  name: 'ListWrapper',
  components: {
    ManageList
  }
}
</script>
